import { useStore } from 'vuex'
import axios from 'axios'

function useOrganization (baseURL) {
  const store = useStore()
  const urlOrg = 'https://organization.' + baseURL + '/api/v1/organization'
  const urlRol = 'https://organization.' + baseURL + '/api/v1/rol'
  const urlSta = 'https://organization.' + baseURL + '/api/v1/status'

  async function getRoles () {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.get(urlRol, headers)
      let arrayRoles = JSON.parse(response.data.data)
      localStorage.setItem('roles', response.data.data)
      arrayRoles.forEach(element => {
        store.commit('setRol', element)
      })
      return arrayRoles
    } catch (error) {
      if (error.response.data.code == 400) {
        let arrayRoles = []
        return arrayRoles
      }
      return error.response.data
    }
  }

  async function deleteRol (id) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.delete(urlRol + '/' + id, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function deleteOrganization (id) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.delete(urlOrg + '/' + id, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function updateLast (id) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.put(urlRol + '/' + id, {}, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function newRol (data) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.post(urlRol, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function changeRol (data) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.put(urlRol, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function getRolesOrganization (id) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.get(urlRol + '/organization/' + id, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function newOrganization (data) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.post(urlOrg, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function updateOrganization (id, data) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.put(urlOrg + '/' + id, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function getOrganization (id) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.get(urlOrg + '/' + id, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function putEmissionKey () {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.put(
        urlOrg + '/key/' + store.state.rol.orgId,
        {},
        headers,
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  async function getStatus () {
    const headers = { headers: { Authorization: store.state.user.sub } }
    try {
      const response = await axios.get(
        urlSta + '/' + store.state.rol.orgId,
        headers,
      )
      let arrayStatus = response.data.data
      store.state.rol.status = arrayStatus[arrayStatus.length - 1].value
      return arrayStatus
    } catch (error) {
      return error.response.data
    }
  }

  async function setStatus (status) {
    const headers = { headers: { Authorization: store.state.user.sub } }
    let data = {
      _organizationId: store.state.rol.orgId,
      _status: status,
    }
    try {
      const response = await axios.post(urlSta, data, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  return {
    getRoles,
    deleteRol,
    deleteOrganization,
    updateLast,
    newOrganization,
    updateOrganization,
    getRolesOrganization,
    getOrganization,
    newRol,
    changeRol,
    putEmissionKey,
    getStatus,
    setStatus,
  }
}

export default useOrganization
