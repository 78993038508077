import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import LayoutHome from '@/layouts/LayoutHome'
import LayoutTopSide from '@/layouts/LayoutTopSide'
import store from '@/store'
import { computed } from 'vue'
import { createI18n } from 'vue-i18n'
import messages from '../lang'

const i18n = createI18n({
  legacy: false,
  locale: 'es',
  fallbackLocale: 'es',
  globalInjection: true,
  messages,
})

const t = i18n.global.t

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LayoutHome,
    meta: {
      title: t('Titles.COPLA'),
    },
    beforeEnter: (to, from, next) => {
      let authenticated = computed(() => {
        return store.getters.authenticated
      })
      if (authenticated.value) {
        next('/sessions')
      }
      else {
        next()
      }
    },
  },
  {
    path: '/organization',
    component: LayoutTopSide,
    children: [
      {
        path: '/organization/new',
        name: 'NewOrganization',
        component: () =>
          import('@/views/pages/organization/OrganizationNew.vue'),
        meta: {
          title: t('Titles.NewOrganization'),
        },
      },
      {
        path: '/organization',
        name: 'UpdateOrganization',
        component: () =>
          import('@/views/pages/organization/OrganizationUpdate.vue'),
        meta: {
          title: t('Titles.OrganizationData'),
        },
      },
      {
        path: '/organization/members',
        name: 'MembersOrganization',
        component: () =>
          import('@/views/pages/organization/OrganizationMembers.vue'),
        meta: {
          title: t('Titles.OrganizationMembers'),
        },
      },
      {
        path: '/organization/key',
        name: 'KeyOrganization',
        component: () =>
          import('@/views/pages/organization/OrganizationKey.vue'),
        meta: {
          title: t('Titles.EmissionKey'),
        },
      },
      {
        path: '/organization/subscription',
        name: 'SubscriptionOrganization',
        component: () =>
          import('@/views/pages/organization/OrganizationSubscription.vue'),
        meta: {
          title: t('Titles.Subscription'),
        },
      },
      {
        path: '/organization/members/new',
        name: 'NewMember',
        component: () =>
          import('@/views/pages/organization/OrganizationMembersNew.vue'),
        meta: {
          title: t('Titles.NewMember'),
        },
      },
    ],
  },
  {
    path: '/services',
    component: LayoutTopSide,
    children: [
      {
        path: '',
        name: 'Services',
        component: () => import('@/views/pages/Services.vue'),
        meta: {
          title: 'Servicios',
        },
      },
    ],
  },
  {
    path: '/sessions',
    component: LayoutTopSide,
    children: [
      {
        path: '/sessions',
        name: 'ListSession',
        component: () => import('@/views/pages/session/SessionList.vue'),
        meta: {
          title: t('Titles.SessionsList'),
        },
      },
      {
        path: '/sessions/new',
        name: 'NewSession',
        component: () => import('@/views/pages/session/SessionNew.vue'),
        meta: {
          title: t('Titles.NewSession'),
        },
      },
      {
        path: '/sessions/vocabulary',
        name: 'SessionVocabulary',
        component: () => import('@/views/pages/session/SessionVocabulary.vue'),
        meta: {
          title: t('Titles.Vocabularies'),
        },
      },
      {
        path: '/sessions/data',
        name: 'SessionData',
        component: () => import('@/views/pages/session/SessionUpdate.vue'),
        meta: {
          title: t('Titles.SessionData'),
        },
      },
      {
        path: '/sessions/writer',
        name: 'SessionWriter',
        component: () => import('@/views/pages/session/SessionWriter.vue'),
        meta: {
          title: t('Titles.Writer'),
        },
      },
    ],
  },
  {
    path: '/payments',
    component: LayoutTopSide,
    children: [
      {
        path: '/payments',
        name: 'CurrentCosts',
        component: () => import('@/views/pages/payments/PaymentsCosts.vue'),
        meta: {
          title: t('Titles.CurrentCosts'),
        },
      },
    ],
  },
  {
    path: '/',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render () {
        return h(resolveComponent('router-view'))
      },
    },
    meta: {
      title: '',
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      {
        path: 'view/:code',
        name: 'View',
        component: () => import('@/views/pages/Viewer'),
      },
      {
        path: 'view/:code/:language?',
        name: 'viewLanguage',
        component: () => import('@/views/pages/Viewer'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  document.title = 'Copla.io | ' + `${to.meta.title}`
  let authenticated = computed(() => {
    return store.getters.authenticated
  })
  if (!authenticated.value) {
    let user = localStorage.getItem('user')
    if (user !== null && user !== 'undefined') {
      store.commit('setUser', JSON.parse(user))
    }
    let roles = localStorage.getItem('roles')
    if (
      roles !== null &&
      store.getters.rolesCount == 0 &&
      roles !== 'undefined'
    ) {
      let arrayRoles = JSON.parse(roles)
      arrayRoles.forEach(element => {
        store.commit('setRol', element)
      })
    }
    let activeRol = localStorage.getItem('activeRol')
    if (activeRol !== null && activeRol !== 'undefined') {
      store.commit('setActiveRol', activeRol)
    }
  }
  if (!authenticated.value) {
    if (
      to.name !== 'Home' &&
      to.name !== 'View' &&
      to.name !== 'viewLanguage' &&
      to.name !== 'Login'
    ) {
      //Poner todas las paginas publicas
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
      next()
  }
})

export default router
