<template>
  <div>
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeaderUnique @loaded="headerLoaded" :class="{ 'sidebar-true': sidebarVisible }" />
      <main style="min-height: 90vh" class="body flex-grow-1 px-3" :class="sidebarVisible ? 'sidebar-true' : 'sidebar-false'" v-if="load">
        <router-view />
      </main>
      <app-footer-inside />
    </div>
    <AppSidebar type="Live" />
  </div>
</template>
<script>
import AppFooterInside from '@/components/AppFooterInside.vue'
import AppHeaderUnique from '@/components/AppHeaderUnique.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import { useStore } from 'vuex'
import { computed } from "vue";

export default {
  name: 'DefaultLayout',
  components: {
    AppHeaderUnique,
    AppSidebar,
    AppFooterInside,
  },
}
</script>

<script setup>
import { ref } from 'vue'
const store = useStore()
let load = ref('false')
const headerLoaded = () => {
  load.value = true
}
const sidebarVisible = computed(() => store.state.sidebarVisible)
</script>

<style lang="scss" scoped>
.sidebar-true {
  width: unset;
  margin-left: 16rem;
}

</style>
